const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dictum ex ligula, quis ultrices felis ornare et. Morbi bibendum porta neque quis aliquam. Fusce varius massa at quam pharetra, vitae mollis velit ornare. In hac habitasse platea dictumst. Suspendisse hendrerit nec nulla vitae dignissim. Etiam id fermentum turpis. Etiam finibus rutrum tristique.\n

Nullam venenatis enim ac dolor dictum, a volutpat ipsum euismod. Maecenas bibendum imperdiet dignissim. Duis tempus volutpat ornare. Ut luctus congue tristique. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras tincidunt lobortis tortor. Donec quis orci diam. In sollicitudin tincidunt convallis. Donec sagittis et nisi sit amet mollis.


Sed consequat varius aliquet. Interdum et malesuada fames ac ante ipsum primis in faucibus. Praesent sit amet semper ligula, sit amet ullamcorper neque. Suspendisse vehicula eleifend lacus, sed venenatis nisl eleifend id. Cras lectus eros, rhoncus non hendrerit eget, dictum quis risus. Vivamus mauris leo, aliquet in volutpat nec, fermentum tristique nibh. Duis cursus nunc a diam porta facilisis. Fusce lacinia urna vitae porta sollicitudin. Suspendisse potenti. 

Suspendisse nec tortor erat. Ut libero quam, scelerisque a ultricies id, porta eget dui. Etiam sit amet sem viverra purus lobortis viverra. Ut fermentum suscipit ex, ut mattis diam iaculis id. Fusce et bibendum felis. Proin et neque magna. Fusce fringilla varius enim et tempor. Nulla consequat, leo pulvinar lacinia porttitor, ante orci rutrum elit, id finibus massa lacus eu tellus. Nam at vehicula ipsum. Sed sit amet semper erat. Aenean accumsan lorem sed libero porta, sed vestibulum libero congue. Praesent tempor laoreet nisi at tempor. Curabitur vestibulum fermentum aliquet. 

Integer vel malesuada purus. Donec vitae velit non neque aliquam condimentum et a purus. Curabitur pellentesque condimentum posuere. Nullam a metus ut dui laoreet pulvinar. Duis at elit eu purus sodales volutpat. Curabitur facilisis ante nec sapien volutpat lobortis. Nullam ac imperdiet metus. Sed congue nulla at sollicitudin consequat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer posuere posuere libero, sed pretium tortor bibendum vitae. Duis ultricies ipsum ex, eu finibus nisi feugiat in. Sed neque dui, porta ut nisi sed, interdum sodales nibh. Curabitur aliquam id tellus vestibulum tincidunt.`;
export { text };
